import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        let interval = this.element.dataset.interval || 5
        let refreshCount = this.element.dataset.refreshCount || $('[data-refresh="true"]').length

        setTimeout(() => {
            this.fetch.bind(this)(refreshCount);
        }, interval * 1000);
    }

    fetch(refreshCount) {
        fetch(this.element.dataset.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
            }).then(() => {
                if (refreshCount > 0 && $('[data-refresh="true"]').length === 0) {
                    window.location.reload()
                }
            })
        }
    }

